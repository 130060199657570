
import { defineComponent, ref, onMounted, computed } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import ApiService from "@/core/services/ApiService";
import * as JWT from '@/core/services/JwtService';

export default defineComponent({
  name: "kt-account",
  components: {
    Dropdown3,
  },
  setup() {
    let totalDecarations = ref(0);
    let totalResponsables = ref(0);
    
    onMounted(() => {
      getDeclarations();
      getResponsables();
      
    });

    const getDeclarations= () => {
      ApiService.query('/api/declarations/permissionnaire/' + JWT.getAuthUser().id, {
        params: {
          size: 100000000
        }
      }).then((res: any) => {
            totalDecarations.value = res.data.totalElements;
      }).catch(error => { 
      });
    }

    const getResponsables= () => {

      ApiService.query('/api/responsableTechnique/permissionnaires/' + JWT.getAuthUser().id, {
        params: {
          size: 100000000,
        }
      }).then((res: any) => {
        totalResponsables.value = res.data.totalItems;
      }).catch(error => { 
        
      });
    }

    /*
    * Permet de recuperer une entité spécifique
    */
    const permissionnaire = computed(() => {
      return JWT.getAuthUser();
    });

    return { 
      totalDecarations,
      totalResponsables,
      permissionnaire
    }
  },
});
